import React from 'react';

import './App.css';
import { Container, CssBaseline } from '@material-ui/core';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PrivacyPolicy } from './components/privacy-policy';
import { Home } from './components/home';
import { TermsOfService } from './components/terms-of-service';
import { Support } from './components/support';
import { Eula } from './components/eula';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />,
  },
  {
    path: '/eula',
    element: <Eula />,
  },
  {
    path: '/support',
    element: <Support />,
  },
]);

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <main>
        <Container maxWidth="md">
          <RouterProvider router={router} />
        </Container>
      </main>
    </div>
  );
}

export default App;
