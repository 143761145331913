import { Menubar } from './menubar';

export const Eula = () => {
  return (
    <>
      <Menubar title="EULA" />
      <p>
        Objectionable Content and Abusive Users: Our social app strictly
        prohibits the upload, publication, display, or transmission of any
        content that is illegal, harmful, abusive, obscene, threatening,
        defamatory, or otherwise objectionable. We reserve the right to remove
        or disable any content that violates this policy, and to terminate the
        accounts of users who engage in such behavior.
      </p>
      <p>
        We also prohibit the use of our social app for any harassing, bullying,
        or abusive behavior towards other users. We will not tolerate any form
        of discrimination or hate speech based on race, ethnicity, gender,
        sexual orientation, religion, or any other personal characteristic.
      </p>
      <p>
        We may permanently delete posts, ban accounts or device if we identify
        the following violations
      </p>
      <ul>
        <li>
          Under 17
          <ul>
            <li>Our minimum age requirement for user must be above 17.</li>
          </ul>
        </li>
        <li>
          Sending inappropriate content
          <ul>
            <li>
              Post, promote any child sexual abuse material (CSAM), or content
              that depicts non-consensual sex acts such as rape or molestation,
              or content that facilitates human trafficking.
            </li>
          </ul>
        </li>
        <li>
          Cyberbullying
          <ul>
            <li>Promote or threaten violence.</li>
          </ul>
        </li>
        <li>
          Sharing privacy information, marketing
          <ul>
            <li>
              Post, promote any personal information (phone number, mail
              address, address), or promoting any marketing schemes
            </li>
          </ul>
        </li>
      </ul>
      <p>
        By using our social app, you agree to abide by these guidelines and to
        report any violations you encounter. Failure to comply with these rules
        may result in the immediate suspension or termination of your account.
      </p>
    </>
  );
};
