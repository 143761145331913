import { Box, Grid, Link, Typography } from '@mui/material';
import { Menubar } from './menubar';

export const Home = () => {
  return (
    <>
      <Menubar title="Harro.me" />
      <Grid
        direction="column"
        alignItems="center"
        justifyContent="center"
        container
        style={{ minHeight: '100vh' }}
      >
        <img src="logo192.png" alt="Harro.me" width={80} />

        <Typography component="p" variant="body1" textAlign="center">
          Introducing our revolutionary chat app that lets you chat anonymously
          without the need for email sign-ins. Say goodbye to gender and race
          differences as our app provides a safe and inclusive space for
          everyone to chat openly and honestly.
        </Typography>

        <Box sx={{ height: 30 }} />
        <Typography component="p" variant="body1" textAlign="center">
          Our user-friendly interface makes it easy to start chatting right
          away. No need to fill out long profiles or navigate complicated
          settings. Simply download the app and start chatting with like-minded
          individuals from around the world. Whether you're looking for casual
          chats or more meaningful conversations, our app provides a safe and
          welcoming environment for you to connect and communicate anonymously.
          Join millions of users and download our app today to experience the
          power of anonymous and open communication.
        </Typography>

        <Box sx={{ height: 30 }} />

        <Grid direction="row" spacing={10}>
          <Link href="/support">Support</Link>
        </Grid>
        <p>Documentations</p>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <Grid item>
            <Link href="/privacy-policy">Privacy Policy</Link>
          </Grid>
          <Grid item>
            <Link href="/eula">EULA</Link>
          </Grid>
          <Grid item>
            <Link href="/terms-of-service">Terms of Service</Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
