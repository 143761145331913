import { Typography } from '@mui/material';
import { Menubar } from './menubar';

export const TermsOfService = () => {
  return (
    <>
      <Menubar title="Terms of Service" />
      <p>
        Welcome to <strong>harro.me</strong> (the "Site" or "App"). By accessing
        or using the Site or App, you agree to be bound by the following terms
        and conditions ("Terms of Service"). Please read them carefully before
        using the Site or App.{' '}
      </p>
      <Typography component="h2" variant="h5" color="inherit" noWrap>
        General
      </Typography>
      <p>
        By accessing or using the Site or App, you agree to be bound by these
        Terms of Service, as well as our Privacy Policy, which is incorporated
        by reference. If you do not agree to these Terms of Service or the
        Privacy Policy, you may not use the Site or App.
      </p>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Use of the Site and App
      </Typography>
      <p>
        You may use the Site and App only for lawful purposes and in accordance
        with these Terms of Service. You may not use the Site or App in any way
        that violates any applicable federal, state, local, or international law
        or regulation.
      </p>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Intellectual Property
      </Typography>
      <p>
        All content and materials on the Site and App, including but not limited
        to text, graphics, logos, images, and software, are the property of{' '}
        <strong>harro.me</strong> or its content suppliers and are protected by
        United States and international copyright laws.
      </p>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Disclaimer of Warranties
      </Typography>
      <p>
        The Site and App are provided "as is" and without warranties of any
        kind, either express or implied. <strong>harro.me</strong> disclaims all
        warranties, express or implied, including but not limited to warranties
        of merchantability and fitness for a particular purpose.
      </p>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Limitation of Liability
      </Typography>
      <p>
        In no event shall <strong>harro.me</strong> be liable for any direct,
        indirect, incidental, special, or consequential damages arising out of
        or in any way connected with the use of the Site or App or with the
        delay or inability to use the Site or App, or for any information,
        products, and services obtained through the Site or App, or otherwise
        arising out of the use of the Site or App, whether based on contract,
        tort, strict liability, or otherwise.
      </p>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Indemnification
      </Typography>
      <p>
        You agree to indemnify, defend, and hold harmless{' '}
        <strong>harro.me</strong>, its affiliates, officers, directors,
        employees, agents, and licensors from and against any and all claims,
        liabilities, damages, losses, costs, expenses, or fees (including
        reasonable attorneys' fees) arising from or relating to your use of the
        Site or App or your violation of these Terms of Service.
      </p>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Modification of Terms
      </Typography>
      <p>
        <strong>harro.me</strong> reserves the right to modify or replace these
        Terms of Service at any time in its sole discretion. Your continued use
        of the Site or App after any such changes constitutes your acceptance of
        the new Terms of Service.
      </p>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Governing Law
      </Typography>
      <p>
        These Terms of Service shall be governed by and construed in accordance
        with the laws of the State of [Your state], without regard to its
        conflict of laws provisions.
      </p>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Entire Agreement
      </Typography>
      <p>
        These Terms of Service, together with the Privacy Policy and any
        additional terms to which you agree when using particular elements of
        the Site or App, constitute the entire agreement between you and{' '}
        <strong>harro.me</strong> with respect to the Site and App.
      </p>

      <p>
        If you have any questions or concerns about these Terms of Service or
        the Site or App, please contact us at [Your contact email].
      </p>
    </>
  );
};
