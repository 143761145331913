import { Typography } from '@mui/material';
import { Menubar } from './menubar';

export const Support = () => {
  return (
    <>
      <Menubar title="Support" />
      <h1>Support</h1>

      <Typography component="h2" variant="h5" color="inherit" noWrap>
        Our support team are managed by{' '}
        <span className="company_name">MYST STUDIOS</span>
      </Typography>
      <p>
        Please report any issue by mailing us to our inbox at{' '}
        <a href="mailto:support@myst.my">support@myst.my</a>
      </p>
    </>
  );
};
